<template>
  <div>
    <el-collapse v-model="activeNames" accordion @change="handleChange">
      <el-collapse-item title="新增数据权限" name="add">
        <div style="padding: 30px 0">
          <div class="checkboxAll">
            <span>字段</span>
            <div style="330px">
              <el-checkbox
                v-model="allIsShow"
                @change="handleAllEdit($event, 'isShow')"
                style="width: 60px"
                >可见</el-checkbox
              >
              <el-checkbox
                v-model="allIsWrite"
                @change="handleAllEdit($event, 'isWrite')"
                style="width: 60px"
                >可写</el-checkbox
              >
              <el-checkbox
                v-model="allIsRequire"
                @change="handleAllEdit($event, 'isRequire')"
                style="width: 60px"
                >必填</el-checkbox
              >
              <el-checkbox
                v-model="allIsPrint"
                @change="handleAllEdit($event, 'isPrint')"
                style="width: 60px"
                >打印</el-checkbox
              >
            </div>
          </div>
          <div class="list" v-for="item in editOptions" :key="item.itemId">
            <span>{{ item.name }}</span>
            <div style="330px">
              <el-checkbox
                v-model="item.isShow"
                style="width: 60px"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isWrite"
                style="width: 60px"
                :disabled="disabled.includes(item.code)"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isRequire"
                style="width: 60px"
                :disabled="disabled.includes(item.code)"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isPrint"
                style="width: 60px"
              ></el-checkbox>
            </div>
          </div>
          <div style="float: right; margin: 40px 0">
            <el-button size="small" @click="activeNames = ''">取消</el-button>
            <el-button size="small" @click="handleRequire" :loading="loading">确定</el-button>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="查看编辑数据权限" name="update">
        <div style="padding: 30px 0">
          <div class="checkboxAll">
            <span>字段</span>
            <div style="330px">
              <el-checkbox
                v-model="allIsShow"
                @change="handleAllEdit($event, 'isShow')"
                style="width: 60px"
                >可见</el-checkbox
              >
              <el-checkbox
                v-model="allIsWrite"
                @change="handleAllEdit($event, 'isWrite')"
                style="width: 60px"
                >可写</el-checkbox
              >
              <el-checkbox
                v-model="allIsRequire"
                @change="handleAllEdit($event, 'isRequire')"
                style="width: 60px"
                >必填</el-checkbox
              >
              <el-checkbox
                v-model="allIsPrint"
                @change="handleAllEdit($event, 'isPrint')"
                style="width: 60px"
                >打印</el-checkbox
              >
            </div>
          </div>
          <div class="list" v-for="item in editOptions" :key="item.itemId">
            <span>{{ item.name }}</span>
            <div style="330px">
              <el-checkbox
                v-model="item.isShow"
                style="width: 60px"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isWrite"
                style="width: 60px"
                :disabled="disabled.includes(item.code)"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isRequire"
                style="width: 60px"
                :disabled="disabled.includes(item.code)"
              ></el-checkbox>
              <el-checkbox
                v-model="item.isPrint"
                style="width: 60px"
              ></el-checkbox>
            </div>
          </div>
          <div style="float: right; margin: 40px 0">
            <el-button size="small" @click="activeNames = ''">取消</el-button>
            <el-button size="small" @click="handleRequire" :loading="loading"
              >确定</el-button
            >
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
// import FormRender from "@/views/common/form/FormRender";
import {
  getFieldPermission,
  updateFieldPermission,
} from "@/api/fieldPermission";
export default {
  name: "FieldPermission",
  data() {
    return {
      disabled: ["SerialNumber", "CreatedBy", "OwnerId", "Created", "Updated"],
      activeNames: "add",
      editOptions: [],
      allIsShow: false,
      allIsWrite: false,
      allIsRequire: false,
      allIsPrint: false,
      loading: false,
    };
  },

  created() {
    this.getList();
  },
  watch: {
    editOptions: {
      deep: true,
      handler(newVal) {
        if (!newVal) return;
        if (newVal.every((item) => item.isShow)) this.allIsShow = true;
        else this.allIsShow = false;
        if (newVal.every((item) => item.isWrite)) this.allIsWrite = true;
        else this.allIsWrite = false;
        if (newVal.every((item) => item.isRequire)) this.allIsRequire = true;
        else this.allIsRequire = false;
        if (newVal.every((item) => item.isPrint)) this.allIsPrint = true;
        else this.allIsPrint = false;
      },
    },
  },
  methods: {
    async handleRequire() {
      this.loading = true;
      await updateFieldPermission(this.editOptions);
      this.$message.success("保存成功");
      this.loading = false;
    },
    handleAllEdit(val, str) {
      this.editOptions.forEach((item) => {
        if (this.disabled.includes(item.code)) {
          //   item[str] = item[str];
          return;
        } else {
          item[str] = val;
        }
      });
    },
    handleChange(val) {
      if (!val) return;
      this.getList();
    },
    async getList() {
      let res = await getFieldPermission({
        formId: this.$route.query.code,
        node: this.activeNames,
      });
      this.editOptions = res.data;
      this.editOptions.forEach(item=>{
        if (this.disabled.includes(item.code)) {
          item.isWrite = false
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.checkboxAll {
  display: flex;
  justify-content: space-between;
  background-color: rgb(248, 251, 253);
  padding: 10px;
}
.list {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
</style>
