<template>
  <div class="senior-setup">
    <el-container>
      <el-aside
        width="200px"
        style="box-shadow: 0 0 8px 0 #e0e5f0; border-radius: 4px"
      >
        <el-menu
          default-active="字段权限"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="字段权限">
            <i class="el-icon-lock"></i>
            <span slot="title">字段权限</span>
          </el-menu-item>
          <el-menu-item index="业务规则">
            <i class="el-icon-discover"></i>
            <span slot="title">业务规则</span>
          </el-menu-item>
          <el-menu-item index="关联列表">
            <i class="el-icon-document-copy"></i>
            <span slot="title">关联列表</span>
          </el-menu-item>
          <el-menu-item index="打印模板">
            <i class="el-icon-printer"></i>
            <span slot="title">打印模板</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main style="box-shadow: 0 0 8px 0 #e0e5f0; border-radius: 4px">
        <div style="padding-bottom:20px">
          <div
            class="title"
            style="
              height: 44px;
              box-sizing: border-box;
              border-bottom: 1px solid #f3f5f8;
              line-height: 25px;
              padding-bottom: 10px;
            "
          >
            {{ comp }}
          </div>
          <div class="comp">
            <component :is="compObj[comp]"></component>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import FieldPermission from "./components/FieldPermission";
import BusinessRule from "./components/BusinessRule";
export default {
  name: "FormProSetting",
  components: { FieldPermission, BusinessRule },
  data() {
    return {
      comp: "字段权限",
      index: 1,
      compObj: {
        字段权限: "FieldPermission",
        业务规则: "BusinessRule",
      },
    };
  },
  methods: {
    handleSelect(val) {
      console.log(val);
      this.comp = val;
    },
  },
};
</script>

<style lang="less" scoped>
.senior-setup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.el-container {
  margin: 0 20vw;
  min-width: 1000px;
}

.el-aside {
  background-color: #fff;
  height: 800px;
}

.el-main {
  height: 800px;
  background-color: #fff;
  margin-left: 10px;
}
.el-menu {
  border-right: unset !important;
}
</style>
