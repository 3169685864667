import requestAdmin from '@/utils/requestAdmin.js'

// 获取模块列表
export function getModuleList() {
    return requestAdmin({
        url: `/module/list`,
        method: 'get',

    })
}

// 模块排序
export function moduleListSort(data) {
    return requestAdmin({
        url: `/module`,
        method: 'post',
        data
    })
}

// 表单搜索关联模块
export function formSearch(keyword) {
    return requestAdmin({
        url: `form/search`,
        method: 'get',
        params: {
            keyword
        }
    })
}

// 移动表单
export function formMove(data) {
    return requestAdmin({
        url: `form-group/sort`,
        method: 'put',
        data
    })
}
