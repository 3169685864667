import requestAdmin from '@/utils/requestAdmin.js'

// 获取列表和搜索字段
export function getFieldPermission(data) {
    return requestAdmin({
        url: `/item-limit/list`,
        method: 'post',
        data
    })
}

export function updateFieldPermission(data) {
    return requestAdmin({
        url: `/item-limit`,
        method: 'post',
        data
    })
}
