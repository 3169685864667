<template>
  <div class="BusinessRule">
    <SetHide
      :isHidden.sync="isHidden"
      rulesTitle="数据生效时执行以下函数"
      placeholder='例：UPDATE(目标表单,目标表单.姓名=="张三",目标表单.员工状态,"已转正)"'
      :rulesArr="[
        '请先从左侧面板设定目标表单',
        '再从右侧插入高级函数，如UPDATE(),并按照函数指引进行操作',
        '支持英文模式下运算符(+、-、*、/、>、&lt;、==、!=、&lt;=、>=)',
      ]"
      :moduleList="moduleList"
      SetHideTitle="业务规则"
      :dom.sync="dom"
      :tag="tag"
      @getList="getList"
    ></SetHide>
    <div class="isEmpty">
      <el-empty
        :image-size="200"
        description="您还没添加任何业务规则"
        v-if="formRulesList.length < 1"
      >
        <el-button type="primary" @click="setRules">立刻设置</el-button>
      </el-empty>
      <div v-else>
        <div
          style="
            padding: 6px 24px;
            margin: 16px 0;
            min-height: 24px;
            line-height: 24px;
            background: rgba(255, 117, 39, 0.05);
            padding-left: 24px;
            font-size: 12px;
            font-family: PingFangSC-Regular;
            color: #8893a7;
          "
        >
          说明：当数据生效或者作废时可以设置对应规则让业务实现自动化流转
          <div
            @click="setRules"
            style="float: right; cursor: pointer; color: rgb(16, 127, 255)"
            v-if="formRulesList.length > 0"
          >
            添加规则
          </div>
        </div>
        <div class="formRulesList">
          <div class="item" v-for="item in formRulesList" :key="item.id">
            <div class="effect">
              <div class="icon-box">
                <el-button
                  type="warning"
                  circle
                  icon="el-icon-edit"
                  @click="setRules($event, item.formRules[0], item.tag)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="handleDel(item.formRules[0])"
                ></el-button>
              </div>
              <p>触发事件：数据生效时</p>
              <p>目标表单：{{ item.formRules[0].targetFormName }}</p>
              <p>
                操作方式：{{
                  (item.formRules[0] &&
                    rules[
                      item.formRules[0].expression.code
                        .split(",")[0]
                        .split("(")[0]
                    ]) ||
                  ""
                }}
              </p>
              <p>
                执行规则：{{
                  item.formRules[0] && item.formRules[0].expression.text
                }}
              </p>
            </div>
            <div class="efficacy">
              <div class="icon-box">
                <el-button
                  type="warning"
                  circle
                  icon="el-icon-edit"
                  @click="setRules($event, item.formRules[1], item.tag)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="handleDel(item.formRules[1])"
                ></el-button>
              </div>
              <p>触发事件：数据失效时</p>
              <p>目标表单：{{ item.formRules[1].targetFormName }}</p>
              <p>
                操作方式：{{
                  (item.formRules[1].expression &&
                    rules[
                      item.formRules[1].expression.code
                        .split(",")[0]
                        .split("(")[0]
                    ]) ||
                  ""
                }}
              </p>
              <p>
                执行规则：{{
                  (item.formRules[1].expression &&
                    item.formRules[1].expression.text) ||
                  ""
                }}
              </p>
            </div>
            <div
              style="
                padding: 6px 16px;
                background: #f5f7fa;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #8893a7;
                font-size: 12px;
              "
            >
              <div
                v-if="!item.remark && !item.isAddTitle"
                style="
                  color: #107fff;
                  font-size: 12px;
                  line-height: 20px;
                  cursor: pointer;
                "
                @click="$set(item, 'isAddTitle', true)"
              >
                添加备注
              </div>
              <div style="display: flex; width: 100%" v-else>
                <span>备注：</span>
                <el-input
                  style="width: 600px"
                  type="textarea"
                  v-model="item.remark"
                  v-if="item.isAddTitle"
                ></el-input>
                <span v-else>{{ item.remark }}</span>
                <span v-if="item.isAddTitle">
                  <span
                    style="margin-left: 10px; cursor: pointer"
                    @click="setRulesRemark(item)"
                    >保存</span
                  >
                  <span
                    style="margin-left: 10px; cursor: pointer"
                    @click="$set(item, 'isAddTitle', false)"
                    >取消</span
                  >
                </span>
                <span
                  v-else
                  style="margin-left: 10px; cursor: pointer; color: #107fff"
                  @click="$set(item, 'isAddTitle', true)"
                  >修改</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getModuleList } from "@/api/module";
import { formRuleList, updateRule, delFormRule } from "@/api/design";
import SetHide from "@/components/common/SetHide.vue";
export default {
  components: { SetHide },
  data() {
    return {
      isEmpty: false,
      isHidden: false,
      moduleList: [],
      rules: {
        INSERT: "仅插入数据",
        UPDATE: "仅更新数据",
        UPSERT: "更新和插入数据",
        DELETE: "仅删除数据",
        ADDFILE: "仅在附件控件中追加文件",
        REMOVEFILE: "仅在附件控件中清除文件",
      },
      dom: {},
      tag: "",
    };
  },
  created() {
    this.getList();
  },
  computed: {
    formRulesList() {
      return this.$store.state.formRulesList;
    },
  },
  methods: {
    handleDel(dom) {
      console.log(dom);
      if (!dom.id) return this.$message.info("该数据为空");
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delFormRule(dom.id).then(() => {
            this.getList();
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    setRulesRemark(item) {
      console.log(this.formRulesList);
      this.$set(item, "isAddTitle", false);
      updateRule(this.formRulesList).then(() => {
        this.getList();
      });
    },
    setRules(e, item, tag) {
      console.log(e, item, tag);
      this.dom = item;
      this.tag = tag;
      getModuleList().then((res) => {
        console.log(res);
        let list = res.data;
        list.forEach((item) => {
          item.hasChildren = true;
        });
        this.moduleList = list;
      });
      this.isHidden = true;
    },
    getList() {
      formRuleList(this.$route.query.code).then((res) => {
        this.$store.commit("setFormRulesList", res.data);
        console.log(this.formRulesList);
      });
    },
  },
};
</script>

<style  scoped lang="less">
.formRulesList {
  padding-top: 25px;
  max-height: 600px;
  overflow: auto;
  .item {
    border-radius: 4px;
    border: 1px solid #e0e3e9;
    height: 310px;
    width: 100%;
    height: 50%;
    font-size: 14px;
    color: #304265;
    margin-bottom: 30px;
    box-sizing: border-box;
    &:hover {
      .effect,
      .efficacy {
        .icon-box {
          opacity: 1;
        }
      }
    }
    p {
      line-height: 20px;
    }
    .effect,
    .efficacy {
      position: relative;
      margin: 0 20px 0 40px;
      .icon-box {
        opacity: 0;
        width: 80px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: -18px;
        cursor: pointer;
      }
    }
    .effect {
      .icon-box {
        top: -32px;
      }
    }
    .efficacy {
      border-top: 1px solid #e0e3e9;
    }
  }
}
</style>